import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Chip,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  textFieldClasses,
  Tooltip,
  tooltipClasses,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../data-layer';
import { useRecoilValue } from 'recoil';
import { useFind, useLocales, useTextFilter } from '../../hooks';
import {
  VersionedDocumentStatus,
  VllCategoriesBundleResponse,
  VllCategoryResponse,
  VllChannelResponse,
  VllChannelType
} from '../../API';
import { Stack } from '@mui/system';
import TextField from '../shared/TextField';
import { EPGChannelFilters } from '../EPG/EPGChannels/EPGChannelFilters';
import { Check, Close, Search } from '@mui/icons-material';
import { withChannelLiveFilter, withChannelPremiumFilter, withChannelTypeFilter } from '../../state/Layouts';
import { ALL, FALSE, TrinaryBoolean, TRUE } from '../../utils/types/genericTypes';
import PremiumBadge from '../shared/PremiumBadge';
import AssetImage from '../shared/AssetImage';
import CountryPickerLite from '../shared/CountryPickerLite';
import DraftBadge from '../shared/DraftBadge';
import FormControl from '../shared/FormControl';
import { capitalize } from 'lodash-es';
import LinkWithIcon from '../shared/LinkWithIcon';
import { AppRoutes } from '../../Routes';
import { DataGrid, DataGridColDef } from '../shared/DataGrid';
import { IdBadge } from '../shared/IdBadge';
import { withChannelGridSplitPlane } from '../../state/General';
import EPGChannelForm from '../EPG/EPGChannelForm';
import { EPGChannelsDownload } from '../EPG/EPGChannels/EPGChannelsDownload';

export const testIds = {
  root: 'epg-report.root',
  filterInput: 'epg-report.filter-input',
  linkedFilter: 'epg-report.linked-filter',
  linkedFilterOption: 'epg-report.linked-filter-option'
};

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    height: '100%',
    backgroundColor: theme.palette.background.paper
  },
  textField: {
    maxWidth: 500,
    [`&.${textFieldClasses.root}`]: {
      marginBottom: '0 !important'
    }
  },
  filters: {
    minWidth: 500
  },
  linkedFilter: {
    minWidth: 130
  },
  countryPicker: {
    minWidth: 350
  },
  tooltip: {
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.background.paper
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(2)
    }
  },
  darkBackground: {
    display: 'flex',
    background: theme.palette.mode === 'light' ? theme.palette.common.black : undefined,
    borderRadius: theme.shape.borderRadius
  },
  stack: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left'
  },
  overflow: {
    overflow: 'hidden',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  callsignTooltip: {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(2),
      maxWidth: 'none'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.background.paper
    }
  },
  idBadge: {
    background: 'transparent'
  }
}));

type ChannelRecord = {
  id: string;
  channelNumber?: number | undefined;
  channelName: string;
  logo?: string | undefined;
  isPremium?: boolean | undefined;
  region?: VllCategoriesBundleResponse[] | undefined;
  category?: VllCategoryResponse[] | undefined;
  channelType: string;
  published: boolean;
  isLive: boolean;
  linked: boolean;
  source: string | undefined;
  callsign: string;
};

export function EPGReport(): React.ReactElement {
  const {
    channels: {
      state: { withAllRecords: withAllChannelRecords },
      hook: { getAll: getAllChannels, edit: editChannel }
    },
    contentServiceChannels: {
      state: { withAllRecords: withAllUnlinkedChannelRecords },
      hook: { getAll: getAllUnlinkedChannels }
    },
    categories: {
      state: { withAllRecords: withAllCategoryRecords },
      hook: { getAll: getAllCategories }
    },
    categoryBundles: {
      state: { withAllRecords: withAllCategoryBundleRecords },
      hook: { getAll: getAllCategoryBundles }
    }
  } = useData();

  const searchRef = useRef<HTMLInputElement>();

  const allChannels = useRecoilValue(withAllChannelRecords);
  const allUnlinkedChannels = useRecoilValue(withAllUnlinkedChannelRecords);
  const allCategories = useRecoilValue(withAllCategoryRecords);
  const allCategoryBundles = useRecoilValue(withAllCategoryBundleRecords);
  const [isLoading, setIsLoading] = useState(false);

  // Filters out the categories that aren't in any regions (orphaned categories)
  const filteredCategories = useMemo(() => {
    const categories = allCategoryBundles?.flatMap((region) => region.categories);
    return allCategories?.filter((category) => categories?.includes(category.entityId));
  }, [allCategories, allCategoryBundles]);

  const channelType = useRecoilValue(withChannelTypeFilter);
  const isLive = useRecoilValue(withChannelLiveFilter);
  const isPremium = useRecoilValue(withChannelPremiumFilter);

  const [countries, setCountries] = useState<string[]>([]);
  const [isLinked, setIsLinked] = useState<TrinaryBoolean>(ALL);

  const { classes } = useStyles();
  const { t, localize, currentLang } = useLocales();
  const {
    onFilterInputChange,
    clearFilter,
    filteredResults: filterByText
  } = useTextFilter(['channelNumber', 'channelName', 'callsign']);

  useEffect(() => {
    fetchAll();
  }, []);

  useFind(() => searchRef.current?.focus());

  const fetchAll = async () => {
    setIsLoading(true);
    await Promise.all([getAllChannels(), getAllUnlinkedChannels(), getAllCategories(), getAllCategoryBundles()]);
    setIsLoading(false);
  };

  const getRegionForCategory = (categoryId: string) =>
    allCategoryBundles?.find((region) => region.categories.includes(categoryId));

  const getCategoriesFromChanel = (channel: VllChannelResponse) =>
    filteredCategories?.filter((category) => category.categoryChannels.some((c) => channel.entityId === c.channel));

  const getRegionsFromCategories = (category: VllCategoryResponse[] | undefined) =>
    allCategoryBundles?.filter((region) => category?.some((category) => region.categories.includes(category.entityId)));

  const categoryForChannel = useMemo(
    () =>
      allChannels?.reduce(
        (acc, channel) => ({
          ...acc,
          [channel.entityId]: getCategoriesFromChanel(channel)
        }),
        {} as Record<string, VllCategoryResponse[] | undefined>
      ),
    [allChannels, filteredCategories]
  );

  const regionForChannel = useMemo(
    () =>
      allChannels?.reduce(
        (acc, channel) => ({
          ...acc,
          [channel.entityId]: getRegionsFromCategories(getCategoriesFromChanel(channel))
        }),
        {} as Record<string, VllCategoriesBundleResponse[] | undefined>
      ),
    [allChannels, filteredCategories, allCategoryBundles]
  );

  const renderTitle = (row: ChannelRecord) => (
    <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
      <Typography>{row.channelName}</Typography>
      {row.isPremium && <PremiumBadge />}
      {!row.published && <DraftBadge />}
    </Stack>
  );

  const renderRegions = (row: ChannelRecord) =>
    row.region &&
    renderArray('epg.report.regions', row.region, (value) => {
      return (
        <Stack className={classes.stack} key={value.entityId}>
          <LinkWithIcon align="left" title={value?.regionName} pathname={AppRoutes.epg(value?.entityId)} />
        </Stack>
      );
    });

  const renderCategories = (row: ChannelRecord) =>
    row.category ? (
      renderArray('epg.report.categories', row.category, (value) => {
        const region = getRegionForCategory(value.entityId);
        return (
          <Stack className={classes.stack} key={value.entityId}>
            <LinkWithIcon
              align="left"
              title={`${region?.regionName} / ${localize(value.title)}`}
              pathname={AppRoutes.epg(region?.entityId, value.entityId)}
            />
          </Stack>
        );
      })
    ) : (
      <></>
    );

  const renderArray = <T extends unknown | undefined>(
    key: string,
    array: T[] | undefined,
    render: (value: T) => React.ReactElement | string
  ) => {
    const chip = <Chip size="small" label={t(key, { count: array?.length })} />;
    return array?.length ? (
      <Tooltip
        classes={{ popper: classes.tooltip }}
        arrow
        title={
          <Stack direction="column" alignItems="center" sx={{ maxHeight: 420, overflowY: 'auto' }}>
            {array.map((v) => render(v))}
          </Stack>
        }
      >
        {chip}
      </Tooltip>
    ) : (
      chip
    );
  };

  const renderBoolean = (value: boolean) => (value ? <Check color="success" /> : <Close color="error" />);

  const sortBoolean = (a: boolean, b: boolean) => (a === b ? 0 : a ? -1 : 1);

  const columns: DataGridColDef<ChannelRecord>[] = [
    {
      key: 'channelNumber',
      label: '#',
      baseWidth: 69,
      minWidth: 50,
      align: 'center',
      sortFunction: (a, b) => (a.channelNumber || 0) - (b.channelNumber || 0)
    },
    {
      key: 'logo',
      label: t('epg.report.logo'),
      align: 'center',
      baseWidth: 85,
      minWidth: 85,
      cellRenderer: (row) => row.logo && <AssetImage className={classes.darkBackground} path={row.logo} />
    },
    {
      key: 'channelName',
      label: t('epg.report.channelName'),
      cellRenderer: renderTitle,
      baseWidth: '15%',
      minWidth: 160,
      sortFunction: (a, b) => a.channelName.localeCompare(b.channelName)
    },
    {
      key: 'region',
      label: t('epg.report.region'),
      cellRenderer: renderRegions,
      align: 'center',
      minWidth: 120,
      sortFunction: (a, b) => (a.region?.length || 0) - (b.region?.length || 0)
    },
    {
      key: 'category',
      label: t('epg.report.category'),
      cellRenderer: renderCategories,
      align: 'center',
      minWidth: 120,
      sortFunction: (a, b) => (a.category?.length || 0) - (b.category?.length || 0)
    },
    {
      key: 'channelType',
      label: t('epg.report.channelType'),
      baseWidth: 140,
      minWidth: 130,
      cellRenderer: ({ channelType: value }) =>
        value === VllChannelType.VLL ? value : capitalize(value.toLowerCase()),
      sortFunction: (a, b) => a.channelType.localeCompare(b.channelType)
    },
    {
      key: 'published',
      label: t('epg.report.published'),
      cellRenderer: ({ published }) => renderBoolean(published),
      align: 'center',
      minWidth: 120,
      sortFunction: (a, b) => sortBoolean(a.published, b.published)
    },
    {
      key: 'isLive',
      label: t('epg.report.isLive'),
      cellRenderer: ({ isLive }) => renderBoolean(isLive),
      align: 'center',
      minWidth: 90,
      sortFunction: (a, b) => sortBoolean(a.isLive, b.isLive)
    },
    {
      key: 'source',
      label: t('epg.report.source'),
      minWidth: 85,
      sortFunction: (a, b) => (a.source || '').localeCompare(b.source || '')
    },
    {
      key: 'linked',
      label: t('epg.report.linked'),
      cellRenderer: ({ linked }) => renderBoolean(linked),
      align: 'center',
      minWidth: 100,
      sortFunction: (a, b) => sortBoolean(a.linked, b.linked)
    },
    {
      key: 'callsign',
      label: t('epg.report.callsign'),
      baseWidth: '15%',
      cellRenderer: (row) => (
        <Tooltip
          classes={{ popper: classes.callsignTooltip }}
          title={<IdBadge id={row.callsign} className={classes.idBadge} forceWhiteForeground />}
          arrow
        >
          <div className={classes.overflow}>{row.callsign}</div>
        </Tooltip>
      ),
      sortFunction: (a, b) => a.callsign.localeCompare(b.callsign)
    }
  ];

  const channels = useMemo(
    () => [
      ...(allChannels?.map<ChannelRecord>((channel) => ({
        id: 'linked:' + channel.entityId,
        isPremium: channel.isPremium,
        logo: localize(channel.logo),
        channelNumber: channel.channelNumber,
        channelName: localize(channel.title),
        region: regionForChannel?.[channel.entityId],
        category: categoryForChannel?.[channel.entityId],
        channelType: channel.type,
        published: channel.status === VersionedDocumentStatus.PUBLISHED,
        isLive: channel.isLive,
        linked: true,
        source: channel.channelSource,
        callsign: channel.entityId
      })) || []),
      ...(allUnlinkedChannels
        ?.filter(({ linked }) => !linked)
        ?.map<ChannelRecord>((channel) => ({
          id: 'unlinked:' + channel.id,
          isPremium: channel.isPremium,
          channelName: localize(channel.title),
          channelType: channel.type,
          published: false,
          isLive: channel.isLive,
          linked: false,
          source: channel.channelSource,
          callsign: channel.id
        })) || [])
    ],
    [allChannels, allCategories, allCategoryBundles, allUnlinkedChannels, currentLang]
  );

  const filterFunction = (channel: ChannelRecord): boolean => {
    if (channelType !== ALL && channel.channelType !== channelType) {
      return false;
    }
    if ((isLive === TRUE && !channel.isLive) || (isLive === FALSE && channel.isLive)) {
      return false;
    }
    if ((isPremium === TRUE && !channel.isPremium) || (isPremium === FALSE && channel.isPremium)) {
      return false;
    }
    if (countries.length && !channel.region?.some((region) => region.countries.some((c) => countries.includes(c)))) {
      return false;
    }
    if ((isLinked === TRUE && !channel.linked) || (isLinked === FALSE && channel.linked)) {
      return false;
    }
    return true;
  };

  const filteredChannels = filterByText(channels).filter(filterFunction);

  const filteredIds = filteredChannels.map((channel) => channel.callsign);

  const onRowClick = (row: ChannelRecord) => {
    if (row.linked) {
      editChannel(row.callsign);
    }
  };

  return (
    <div className={classes.root} data-testid={testIds.root}>
      <Stack gap={4}>
        <Stack direction="row" alignItems="center" gap={4}>
          <Typography variant="h5" sx={{ mr: 4 }}>
            {t('epg.channels.channels')}
          </Typography>
          <TextField
            fullWidth
            debounced
            clearable
            inputRef={searchRef}
            placeholder={t('epg.report.filter_by_keywords')}
            className={classes.textField}
            onChange={onFilterInputChange}
            onClear={clearFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            data-testid={testIds.filterInput}
          />
          <div style={{ flexGrow: 1 }} />
          <EPGChannelsDownload color="primary" variant="contained" channelIdsList={filteredIds} />
        </Stack>
        <Stack direction="row" gap={4} alignItems="center">
          <Stack direction="row" alignItems="center" gap={4} className={classes.filters}>
            <EPGChannelFilters />
          </Stack>
          <FormControl className={classes.linkedFilter}>
            <InputLabel id="linked-filter">{t('epg.report.linked_filter')}</InputLabel>
            <Select
              labelId="linked-filter"
              value={isLinked}
              onChange={({ target: { value } }) => setIsLinked(value as TrinaryBoolean)}
              defaultValue={ALL}
              data-testid={testIds.linkedFilter}
            >
              <MenuItem value={ALL} data-testid={testIds.linkedFilterOption} data-value={ALL}>
                {t('filters.all')}
              </MenuItem>
              <MenuItem value={TRUE} data-testid={testIds.linkedFilterOption} data-value={TRUE}>
                {t('general.linked')}
              </MenuItem>
              <MenuItem value={FALSE} data-testid={testIds.linkedFilterOption} data-value={FALSE}>
                {t('general.unlinked')}
              </MenuItem>
            </Select>
          </FormControl>
          <div style={{ flexGrow: 1 }} />
          <CountryPickerLite
            className={classes.countryPicker}
            label={t('country_picker.filter_by_countries')}
            value={countries}
            onChange={setCountries}
          />
        </Stack>
      </Stack>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={filteredChannels}
        withColumnSizes={withChannelGridSplitPlane}
        onRowClick={onRowClick}
        rowKey="id"
        useVirtualization
      />
      <EPGChannelForm />
    </div>
  );
}
